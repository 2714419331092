import React from "react";
import "./HomeMiniTable.css";

interface HomeMiniTableI {
  name: string;
  gold: string;
  platinum: string;
  infinity: string;
  imageBenefit: string;
}

export const HomeMiniTable = (props: HomeMiniTableI): JSX.Element => {
  const { name, gold, platinum, infinity, imageBenefit } = props;
  return (
    <div>
      <div className="Home-concept">
        <span className="Home-text">
          <img src={imageBenefit} className="Home-text-image" alt=""></img>{" "}
          {name}{" "}
        </span>
        <div className="Home-tick-table-mini">
          <div>
            <p className="Home-tick-table-text"></p>
          </div>
        </div>
        <div className="Home-tick-table-gold">
          <div>
            <p className="Home-tick-table-text">{gold}</p>
          </div>
        </div>
        <div className="Home-tick-table-platinum">
          <div>
            <p className="Home-tick-table-text">{platinum}</p>
          </div>
        </div>
        <div className="Home-tick-table-infinity">
          <div>
            <p className="Home-tick-table-text">{infinity}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeMiniTable;
