import React, { useState } from "react";
import "./App.css";
import HomeTick from "./components/HomeTick/HomeTick";
import { useTranslation } from "react-i18next";
import HomeMiniTable from "./components/HomeMiniTable/HomeMiniTable";
import i18n from "./i18n";

function App() {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(true);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const handleClick = () => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive4(false);
  };
  const handleClick2 = () => {
    // 👇️ toggle isActive state on click
    setIsActive2((current) => !current);
    setIsActive(false);
    setIsActive3(false);
    setIsActive4(false);
  };
  const handleClick3 = () => {
    // 👇️ toggle isActive state on click
    setIsActive3((current) => !current);
    setIsActive2(false);
    setIsActive(false);
    setIsActive4(false);
  };
  const handleClick4 = () => {
    // 👇️ toggle isActive state on click
    setIsActive4((current) => !current);
    setIsActive2(false);
    setIsActive3(false);
    setIsActive(false);
  };

  return (
    <div className="App">
      <header className="Benefits-header">
        <a href="https://www.exclusivetravelerclub.com/es">
          <img
            src="./images/benefits_header.jpeg"
            alt=""
            className="Benefits-header-image"
          ></img>
        </a>
        <div className="Benefits-languages">
          <div className="Benefits-language-dropdown">
            <select
              className="Benefits-language-button"
              value={i18n.language}
              onChange={(e) => i18n.changeLanguage(e.target.value)}
            >
              <option value="es">ES</option>
              <option value="en">EN</option>
              <option value="fr">FR</option>
              <option value="it">IT</option>
              <option value="pt">PT</option>
            </select>
          </div>
        </div>
      </header>
      <div className="Benefits-table">
        <div>
          <div className="Table-home">
            <p className="Table-escrito">{t("Table.title")}</p>
            <button
              className={isActive ? "Table-button-active" : "Table-button"}
              onClick={handleClick}
            >
              <img
                src="./images/benefits_premium.png"
                className="Table-image"
                alt=""
              ></img>
              <p className="Table-text">PREMIUM</p>
            </button>
            <button
              className={isActive2 ? "Table-button-active" : "Table-button"}
              onClick={handleClick2}
            >
              <img
                src="./images/benefits_gold.png"
                className="Table-image"
                alt=""
              ></img>
              <p className="Table-text">GOLD</p>
            </button>
            <button
              className={isActive3 ? "Table-button-active" : "Table-button"}
              onClick={handleClick3}
            >
              <img
                src="./images/benefits_platinum.png"
                className="Table-image"
                alt=""
              ></img>
              <p className="Table-text">PLATINUM</p>
            </button>
            <button
              className={isActive4 ? "Table-button-active" : "Table-button"}
              onClick={handleClick4}
            >
              <img
                src="./images/benefits_infinity.png"
                className="Table-image"
                alt=""
              ></img>
              <p className="Table-text">INFINITY</p>
            </button>
          </div>

          <HomeTick
            name={t("HomeTick.ben1")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben2")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben3")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben4")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben5")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben6")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben7")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben8")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben9")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben10")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben11")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben12")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben13")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben14")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben15")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben16")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben17")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben18")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben19")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben20")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben21")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben22")}
            premium
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben23")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben24")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben25")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben26")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben27")}
            premium={false}
            gold={false}
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben28")}
            premium={false}
            gold={false}
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben29")}
            premium={false}
            gold={false}
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben30")}
            premium={false}
            gold={false}
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben31")}
            premium={false}
            gold={false}
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben32")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben33")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben34")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben35")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben36")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben37")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben38")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben39")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben40")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben41")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben42")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/exclusiveBenefit.png"}
          ></HomeTick>
          <HomeMiniTable
            gold={"4%"}
            platinum={"5%"}
            infinity={"6%"}
            name={t("HomeTick.ben43")}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeMiniTable>
          <HomeMiniTable
            gold={"4%"}
            platinum={"5%"}
            infinity={"6%"}
            name={t("HomeTick.ben44")}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeMiniTable>

          {/* Mini tabla custom*/}
          <div>
            <div className="Home-concept-mini">
              <span className="Home-text">
                <img
                  src="./images/cataloniaBenefit.png"
                  className="Home-text-image"
                  alt=""
                ></img>
                {t("HomeTick.ben45")}
              </span>
              <div>
                {" "}
                <div>
                  {" "}
                  <div className="Home-concept-mini-text">
                    <p>Bayahibe, Bavaro, Riviera, Maroma</p>{" "}
                  </div>
                </div>
                <div>
                  {" "}
                  <div className="Home-concept-mini-text2">
                    <div className="Home-tick-table-gold">
                      <div>
                        <p className="Home-tick-table-text">{"42€"}</p>
                      </div>
                    </div>
                    <div className="Home-tick-table-platinum">
                      <div>
                        <p className="Home-tick-table-text">{"60€"}</p>
                      </div>
                    </div>
                    <div className="Home-tick-table-infinity">
                      <div>
                        <p className="Home-tick-table-text">{"90€"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {" "}
                  <div className="Home-concept-mini-text">
                    <p>
                      Royal Tulum, Royal Bavaro, Royal Romana, Costa Mujeres
                    </p>{" "}
                  </div>
                </div>
                <div>
                  {" "}
                  <div className="Home-concept-mini-text2">
                    <div className="Home-tick-table-gold">
                      <div>
                        <p className="Home-tick-table-text">{"54€"}</p>
                      </div>
                    </div>
                    <div className="Home-tick-table-platinum">
                      <div>
                        <p className="Home-tick-table-text">{"72€"}</p>
                      </div>
                    </div>
                    <div className="Home-tick-table-infinity">
                      <div>
                        <p className="Home-tick-table-text">{"90€"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Final Mini tabla custom*/}
          <HomeTick
            name={t("HomeTick.ben46")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben47")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben48")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben49")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben50")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben51")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben52")}
            premium={false}
            gold
            platinum={false}
            infinity={false}
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben53")}
            premium={false}
            gold={false}
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben54")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben55")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben56")}
            premium={false}
            gold
            platinum
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>
          <HomeTick
            name={t("HomeTick.ben57")}
            premium={false}
            gold={false}
            platinum={false}
            infinity
            selected={isActive}
            selected2={isActive2}
            selected3={isActive3}
            selected4={isActive4}
            imageBenefit={"./images/cataloniaBenefit.png"}
          ></HomeTick>

          <p className="Final-text">{t("Final.title")}</p>
          <p className="Final-text">{t("Final.restrict")}</p>
          <p className="Final-text">{t("Final.rest1")}</p>
          <p className="Final-text">{t("Final.rest2")}</p>
          <p className="Final-text">{t("Final.rest3")}</p>
          <p className="Final-text">{t("Final.rest4")}</p>
          <p className="Final-text">{t("Final.rest5")}</p>
          <p className="Final-text">{t("Final.rest6")}</p>
        </div>
      </div>
    </div>
  );
}

export default App;
