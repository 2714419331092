import React from "react";
import "./HomeTick.css";

interface HomeTickI {
  name: string;
  premium: boolean;
  gold: boolean;
  platinum: boolean;
  infinity: boolean;
  selected: boolean;
  selected2: boolean;
  selected3: boolean;
  selected4: boolean;
  imageBenefit: string;
}

export const HomeTick = (props: HomeTickI): JSX.Element => {
  const {
    name,
    premium,
    gold,
    platinum,
    infinity,
    selected,
    selected2,
    selected3,
    selected4,
    imageBenefit,
  } = props;
  return (
    <div className="Home-concept">
      <span className="Home-text">
        <img src={imageBenefit} className="Home-text-image" alt=""></img> {name}{" "}
      </span>
      <div className="Home-tick">
        {premium ? (
          <img
            src="./images/benefits_tick.png"
            className={selected ? "Home-image-active" : "Home-image"}
            alt=""
          ></img>
        ) : null}
      </div>
      <div className="Home-tick">
        {gold ? (
          <img
            src="./images/benefits_tick.png"
            className={selected2 ? "Home-image-active" : "Home-image"}
            alt=""
          ></img>
        ) : null}
      </div>
      <div className="Home-tick">
        {platinum ? (
          <img
            src="./images/benefits_tick.png"
            className={selected3 ? "Home-image-active" : "Home-image"}
            alt=""
          ></img>
        ) : null}
      </div>
      <div className="Home-tick">
        {infinity ? (
          <img
            src="./images/benefits_tick.png"
            className={selected4 ? "Home-image-active" : "Home-image"}
            alt=""
          ></img>
        ) : null}
      </div>
    </div>
  );
};

export default HomeTick;
